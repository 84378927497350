import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbs: string[] = [];
  links: string[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    
  }

  goTo(path: string): void {
    this.router.navigateByUrl(`/${path}`).then(() => document.location.reload())
  }
  
  ngOnInit(): void {
    this.router.url.split('/').forEach((path, id) => {
      if (id == 0) this.breadcrumbs.push('home');
      else if (path === 'agrominas') return;
      else if(path.split(';').length > 1) {
        switch (path.split(';')[0]) {
          case 'product-details':
            this.breadcrumbs.push('detalhes do produto');
            break;
          case 'search':
            this.breadcrumbs.push('resultado da pesquisa');
            break;
          case 'category':
            let paths = path.split(';');
            let base_link = `${paths[0]};${paths[1]};breadcrumb=`;
            this.activatedRoute.snapshot.params.breadcrumb.split(',').forEach((breadcrumb) => {
              base_link += breadcrumb + ",";
              this.links.push(base_link);
              this.breadcrumbs.push(breadcrumb);
            });
            break;
          default:
            this.breadcrumbs.push(path.split(';')[0]);
            break;
        }
      }
      else this.breadcrumbs.push(path);
    })
  }

}
