import { User } from 'src/app/models/user.model';
import { CryptographyUtil } from '../utils/cryptography/cryptography.util';

export class AuthStorage {

  private static userStorageKey = 'mix-portal.user';
  private static tokenStorageKey = 'mix-portal.token';

  public static setUser(user: User) {
    localStorage.setItem(this.userStorageKey, CryptographyUtil.encrypt(user));
  }

  public static getUser(): User {
    const data = localStorage.getItem(this.userStorageKey);
    const decrypted = CryptographyUtil.decrypt(data);

    return decrypted ? decrypted : null;
  }

  public static setToken(token: string) {
    localStorage.setItem(this.tokenStorageKey, CryptographyUtil.encrypt(token));
  }

  public static getToken(): string {
    const data = localStorage.getItem(this.tokenStorageKey);
    const decrypted = CryptographyUtil.decrypt(data);

    return decrypted ? decrypted : null;
  }

  public static isAuthenticated(): boolean {
    return AuthStorage.getToken() != null;
  }

  public static clear() {
    localStorage.clear();
    sessionStorage.removeItem('cart');
  }

}