import { Component, OnInit, ElementRef, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'src/app/services/toastr.service';
import { GenericResultCommand } from 'src/app/commands/core/generic-result.command';
import { Category } from 'src/app/models/category.model';
import { CartService } from 'src/app/services/cart.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PointsService } from 'src/app/services/points.service';
import { ProductsService } from 'src/app/services/products.service';

import { AuthStorage } from 'src/app/shared/storage/auth.storage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navmobile',
  templateUrl: './navmobile.component.html',
  styleUrls: ['./navmobile.component.scss'],
})
export class NavmobileComponent implements OnInit {
  public arr: string[] = [];
  public user: any | null = AuthStorage.getUser() || null;
  public username: String | null = AuthStorage.getUser()?.name || null;
  isMenuOpen: boolean = false;

  terms: string[] = ['', '', ''];
  totalItem: number = 0;
  category: Array<Category> = [];
  storageUrl: string = environment.apiStorageUrl;
  produtos: any = [];
  total: number = 0;
  totalPoints: number = 0;
  points: any = 0;
  nifUser = AuthStorage.getUser()?.nif || null;
  settings: any;
  constructor(
    private router: Router,
    private pointsService: PointsService,
    private localStorageService: LocalStorageService,
    private service: ProductsService,
    private cartService: CartService,
    public toastr: ToastrService,
    private eRef: ElementRef
  ) {
    this.category = this.localStorageService.getItem('CATEGORYS');
    if (!Array.isArray(this.category)) this.category = [];

    this.settings = this.localStorageService.getItem('SETTINGS');
  }

  ngOnInit(): void {
    this.getDots();
    this.getCartProducts();
    this.getCategory();
    this.getProducts();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      document.getElementById('navbarNavDropdown').classList.remove('show');
    }
  }

  getCategory() {
    this.service.getCategory().then((categorias: GenericResultCommand) => {
      this.category = categorias.data;
      this.localStorageService.setItem('CATEGORYS', this.category);
    });
  }

  public isAuthenticated(): boolean {
    return AuthStorage.isAuthenticated();
  }

  getDots() {
    if (!this.nifUser) return;
    this.pointsService.getPoints(this.nifUser).subscribe(
      (resp) => {
        this.points = `${resp.data.balance}`.replace('.', ',');
      },
      (err) => console.error(err.message)
    )
  }

  getCartProducts() {
    this.cartService.getProducts().subscribe((data) => {
      this.totalItem = data.length;
    });
  }

  public logout() {
    AuthStorage.clear();
    this.router.navigateByUrl('/home').then(() => location.reload());
  }

  getTotal(data: any) {
    let subs: number = 0;
    let points: number = 0;
    if (!data) return;
    for (let item of data) {
      if (item.product.spots == 0) {
        subs +=
          (item.product.promotion_price != 0
            ? item.product.promotion_price
            : item.product.price) * item.qty;
        this.total = subs;
      }

      points += parseFloat(item.product.spots) * item.qty;
      this.totalPoints = points;
    }
  }

  onDelete(i: number) {
    this.produtos.splice(i, 1);
    this.cartService.setCartData(this.produtos);
    location.reload();
    this.getTotal(this.produtos);
  }

  getProducts() {
    this.produtos = this.cartService.getCartData();
    this.getTotal(this.produtos);
  }

  loadSearchTerms(): void {
    let searchTerms = this.localStorageService.getItem('SEARCH-TERM');
    if (searchTerms.length) this.arr = searchTerms;
    else {
      this.category.forEach((object) => {
        this.arr.push(object.name);
        for (const key in object) {
          if (Object.prototype.hasOwnProperty.call(object, key)) {
            if (key === 'children') {
              let childrens = object[key];
              childrens.forEach((element) => {
                this.arr.push(element.name);
                if (Object.prototype.hasOwnProperty.call(element, key)) {
                  if (key === 'children') {
                    let c = element[key];
                    c.forEach((ch) => {
                      this.arr.push(ch.name);
                    });
                  }
                }
              });
            }
          }
        }
      });
      this.localStorageService.setItem('SEARCH-TERM', this.arr);
    }
  }

  ngOnSearch(term: string): void {
    this.terms = [];
    this.arr.find((str) => {
      if (str.toLowerCase().search(term.toLowerCase()) >= 0)
        this.terms.push(str);
    });
  }

  onSearch(term: string): void {
    if (term.length === 0) {
      this.toastr.danger('Atenção', 'Informe o produto que deseja encontrar ou pesquisar!')	
      return      
		}else{
      this.router.navigate(['search', { search: term }]);
    }
    
  }

  search(event: any): void {
    switch (typeof event) {
      case 'string':
        this.onSearch(event);
        break;
      case 'object':
        this.onSearch(event.target.value);
        break;
      default:
        break;
    }
  }

  acordeonClicked(id): void {
    let accordions = <HTMLCollectionOf<Element>>document.getElementsByClassName('accordion-collapse');
    for (let index = 0; index < accordions.length; index++) {
      const element = <Element>accordions[index];
      if (element.id !== `collapseOne${id}`)
        element.classList.remove('show');
    }
  }

  naveCategory(category: number, name: string[]) {
    this.router
      .navigate([
        '/category',
        { categoryID: JSON.stringify(category), breadcrumb: name },
      ])
      .then(() => {
        location.reload();
      });
  }

  myRequest() {
    if (!this.username) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/perfil/pedidos']);
    }
  }

  setNotScrool(): void {
    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) document.getElementsByTagName('app-home')[0].classList.add('no-scroll');
    else document.getElementsByTagName('app-home')[0].classList.remove('no-scroll');
  }

}
