import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Image, Products } from 'src/app/models/products.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card-mobile',
  templateUrl: './card-mobile.component.html',
  styleUrls: ['./card-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardMobileComponent implements OnInit {

  @Input('property') property?:Products;
  image?: Image;
  buttonAvailable!: string;
  activeButton!: boolean;
  percentage!: Number;
  quantity: any = 0;
  storageUrl:String = environment.apiStorageUrl;
  imgUrl!: String;
  env = environment.production;
  constructor(
    private route: Router
  ) { }

  ngOnInit(): void {
    this.removeDecimal();
    this.image = this.property?.images[0];
    this.imgUrl = this.storageUrl + this.image?.name !
    this.checkAvailability();
  }

  removeDecimal(){
    this.percentage = Math.trunc(Number(this.property?.discount));
  }

  navigationProductDetails(product:any) {
      this.route.navigate([
        '/product-details',
        { produto: JSON.stringify(product) },
      ]);
    
     /*this.route.navigate([
      '/product-details',
      { produto: JSON.stringify(product) },
    ]); */
  }

  checkAvailability() {
      this.quantity = this.property?.quantity
    if (this.quantity == 0) {
      this.buttonAvailable = 'INDISPONÍVEL';
      this.activeButton = true;
    } else {
      this.buttonAvailable = 'COMPRAR';
      this.activeButton = false;
    }
  }

}
