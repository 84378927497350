import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericResultCommand } from 'src/app/commands/core/generic-result.command';
import { FaqService } from 'src/app/services/faq.service';
import { Faq } from 'src/app/models/faq.model';
import { PaymentMethods } from 'src/app/services/payment-methods.service';
import { CartService } from 'src/app/services/cart.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AuthStorage } from 'src/app/shared/storage/auth.storage';
import { SettingsService } from 'src/app/services/settings.service';
import { Settings } from 'src/app/models/settings.model';
import { CityStateService } from 'src/app/services/city-state.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  faqs: Array<Faq> = [];
  seloArray: Array<any> = [];
  paymentMethodsArray: Array<any> = [];
  atendimentoArray: Array<any> = [];
  totalItem: number = 0;
  anoPresente: number = 0;
  settings: Array<Settings> | any = [];
  apiStorage = environment.apiStorageUrl;
  constructor(
    private route: Router,
    private faqService: FaqService,
    private SettingsService: SettingsService,
    private paymentMethods: PaymentMethods,
    private cartService: CartService,
    private local_storage_service: LocalStorageService,
    private ServiceCityState: CityStateService

  ) { }

  ngOnInit(): void {
    this.getFaq();
    this.getParams();
    this.getPayments();
    this.getCartProducts();
    this.getSettings()
    this.anoAtual();    

  }

  scrollNavigation() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  getPayments(): void {
    this.paymentMethodsArray = this.local_storage_service.getItem("payment_methods")
  }

  getSettings() {
    this.SettingsService.getSettings().subscribe(
      (response: any) => {
        this.settings = response.data         
        let stamps = JSON.parse(this.settings.stamps)
        stamps.forEach(stamp => {
          this.seloArray.push(stamp.img)
        })
      },
      (error: any) => console.log("error", error)
    )
  }

  getFaq(): void {
    this.faqService
      .getFaq()
      .then((result: GenericResultCommand) => {
        for (let faq of result.data) {
          this.faqs.push(faq);
        }
      })
  }

  getParams(): void { }

  navegar(id: number): void {
    this.route.navigate(['/faq', id]);
  }

  navegarPerfil(rota: string): void {
    this.route.navigate([rota]);
  }


  getCartProducts() {
    this.cartService.getProducts().subscribe((data) => {
      this.totalItem = data.length;
    });
  }

  public isAuthenticated(): boolean {
    return AuthStorage.isAuthenticated();
  }

  navigationMapSite(typeLink: number) {
    switch (typeLink) {
      case 1:
        this.route.navigate(['/']);
        scroll(0, 0)
        break;
      case 2:
        this.route.navigate(['/catalog']);
        scroll(0, 0)
        break;
      case 3:
        if (this.isAuthenticated() == true) {
          this.route.navigate(['/perfil']);
          scroll(0, 0)
        } else {
          const swalParam = Swal.mixin({
            customClass: {
              confirmButton: 'btn confirm pl-4 pr-4',
            },
            buttonsStyling: false,
          });
          swalParam.fire('Opss...', 'Você não está logado', 'info').then(() => {
            this.route.navigate(['/login']);
          });
        }
        break;
      case 4:
        if (this.isAuthenticated() == true) {
          this.route.navigate(['/perfil/pedidos']);
          scroll(0, 0)
        } else {
          const swalParam = Swal.mixin({
            customClass: {
              confirmButton: 'btn confirm pl-4 pr-4',
            },
            buttonsStyling: false,
          });
          swalParam.fire('Opss...', 'Você não está logado', 'info').then(() => {
            this.route.navigate(['/login']);
          });

        }

        break;
      case 5:
        if (this.totalItem != 0) {
          this.route.navigate(['/cart']);
          scroll(0, 0)
        } else {
          const swalParam = Swal.mixin({
            customClass: {
              confirmButton: 'btn confirm pl-4 pr-4',
            },
            buttonsStyling: false,
          });
          swalParam.fire('Opss...', 'Você não tem nada no carrinho', 'info')
        }
        break;
      case 6:

        break;
      case 7:

        break;

      default:
        break;
    }
  }

  anoAtual() {
    const dataAtual = new Date();
    const ano = dataAtual.getFullYear();
    this.anoPresente = ano;
  }
}

