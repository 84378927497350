import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TagInputModule } from 'ngx-chips';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from './services/interceptors/token.interceptor';
import { AuthorizeGuard } from './guards/authorize.guard';
import { PaymentGuard } from './guards/cart.guard';
import { CookiesNotificationComponent } from './components/frame/cookies-notification/cookies-notification.component';
import { SharedComponentsModule } from "./components/frame/shared-components.module";

registerLocaleData(localePt);
@NgModule({
    declarations: [
        AppComponent,
        CookiesNotificationComponent,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'pt' },
        AuthorizeGuard,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        PaymentGuard
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        BsDropdownModule.forRoot(),
        ToastrModule.forRoot(),
        CollapseModule.forRoot(),
        TagInputModule,
        NgxSpinnerModule,
        NgxPaginationModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        SharedComponentsModule
    ]
})
export class AppModule {}
