import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Settings } from 'src/app/models/settings.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SettingsService } from 'src/app/services/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cookies-notification',
  templateUrl: './cookies-notification.component.html',
  styleUrls: ['./cookies-notification.component.scss'],
})
export class CookiesNotificationComponent implements OnInit {
  @Output() cookiesAccepted: EventEmitter<boolean> = new EventEmitter();
  terms: string;
  policy: string;
  apiStorage = environment.apiStorageUrl
  constructor(
    private storage: LocalStorageService,
    private settingsService: SettingsService
  ) {
    let settings: Settings = <Settings>this.storage.getItem('SETTINGS');
    const { terms, privacy_policy } = settings;
    this.terms = terms;
    this.policy =  privacy_policy;
  }

  ngOnInit(): void {
    if (!this.terms && !this.policy) {
      this.settingsService.getSettings().subscribe({
        next: (value) => {
          const { data: settings } = value;
          const { terms, privacy_policy } = <Settings> settings;
          this.terms = terms;
          this.policy = privacy_policy;
        },
        error(err) {
          console.error(err);
        },
      });
    }
  }

  onAcceptCookies(): void {
    this.cookiesAccepted.emit(true);
  }
}
