<ngx-spinner bdColor="rgba(0,0,0,0.8)" size="medium" color="var(---color-white)" type="ball-spin-clockwise"
  [fullScreen]="true">
  <p style="color: white">Carregando...</p>
</ngx-spinner>
<app-navbar [settings]="settings"[category]="categories" ></app-navbar>
<app-navmobile></app-navmobile>
<router-outlet></router-outlet>
<app-cookies-notification *ngIf="showCookiesNotification" (cookiesAccepted)="onAcceptCookies()">
</app-cookies-notification>
<app-whatsapp-botao></app-whatsapp-botao>
<app-footer></app-footer>
