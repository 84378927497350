<ngx-spinner bdColor="rgba(0,0,0,0.8)" size="medium" color="var(---color-white)" type="ball-spin-clockwise"
  [fullScreen]="true">
  <p style="color: white"> Carregando... </p>
</ngx-spinner>


<div class="nav-desk">
  <div class="topo header-color" style="background-color: #fffafa;">
    <div class="container">
      <div class="row py-2">

        <div class="col d-flex justify-content-end align-items-center text-center">
          <div class="d-flex align-items-center">
            <small class="d-flex align-items-center small mr-3" style="color: black;"><i
                class="bi bi-envelope-fill"></i>
              {{settings?.email}}</small>
          </div>
          <div class="d-flex align-items-center">
            <li class="link_settings" *ngIf="settings?.instagram_user != null">
              <a target="_blank" [href]="settings?.instagram_url">
                <span>
                  <i class="bi bi-instagram mr-2"></i>
                </span>

              </a>
            </li>

            <li class="link_settings" *ngIf="settings?.facebook_url != null">
              <a target="_blank" [href]="settings?.facebook_url">
                <span>
                  <i class="bi bi-facebook mr-2"></i>
                </span>
              </a>
            </li>

            <li class="link_settings">
              <a target="_blank" href="https://api.whatsapp.com/send?phone=557199200-1317&text=Podem%20me%20ajudar?">
                <span>
                  <i class="bi bi-whatsapp"></i>
                </span>
              </a>
            </li>
          </div>
        </div>

      </div>
    </div>
  </div>

  <nav id="navbar_top" class="navbar navbar-horizontal navbar-expand-lg navbar-dark "
    style="background-color: #6d7755;">
    <div class="container d-flex justify-content-between">
      <div class=" mr-auto logo">
        <img [routerLink]="['/home']" [src]="settings?.logo_url" [alt]="settings?.full_name" />
      </div>
      <div class="mr-0 ml-auto d-flex justify-content-around pl-5 collapse navbar-collapse" id="collapseBasic"
        [collapse]="isCollapsed">
        <div class="navbar-collapse-header">
          <div class="row ">
            <div class="col-6 collapse-brand">
              <a [routerLink]="['/home']"><img [src]="storageUrl + settings?.logo" [alt]="settings?.full_name" /></a>
            </div>
            <div class="col-6 collapse-close">
              <button style="color: cornsilk; width: 20px" type="button" class="navbar-toggler" data-toggle="collapse"
                data-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false"
                aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed"></button>
            </div>
          </div>
        </div>

        <div class="navbar-nav m-auto">
          <div class="nav-item">
            <a class="nav-link nav-link-icon">
              <div class="input-group">
                <input #searchTerm (keydown.enter)="search($event)" (input)="ngOnSearch(searchTerm.value)"
                  class="form-control button-search" type="search" placeholder="O que você procura?"
                  aria-label="Pesquisa" data-bs-toggle="tooltip" data-bs-placement="bottom"
                  title="Digite o termo da pesquisa e pressione a tecla enter para pesquisar" />
                <span class="input-group-text">

                  <i width="16" height="16" (click)="search(searchTerm.value)" class="bi bi-search"></i>
                </span>
              </div>

              <ul class="search-result {{searchTerm.value!=='' ? '' : 'none'}}">
                <li class="nav-link" *ngFor="let term of terms; let i = index">
                  <span *ngIf="i < 5" (click)="search(term)">{{ term }}</span>
                </li>
              </ul>
            </a>
          </div>
        </div>

        <div>
          <div class="mr-0 ml-auto d-flex justify-content-between align-items-center">
            <div class="nav-item">
              <a class="nav-link nav-link-icon text-black" (click)="myRequest()">
                <h5 class="my-0 py-0" style="cursor: pointer">
                  <i class="bi bi-bag icons" style="color: white;"></i>

                </h5>
              </a>
            </div>
            <div *ngIf="!isAuthenticated()" class="nav-item">
              <a class="nav-link text-black" routerLink="/login">
                <h5 class="my-0 py-0 login" style="cursor: pointer">
                  <i class="bi bi-person icons" style="color: white;"></i>
                </h5>
              </a>
            </div>
            <div class="text-center nav-item dropdown">
              <div class="dropdown-toggle" *ngIf="isAuthenticated()" style="cursor: pointer" id="dropdownMenuLink"
                data-bs-toggle="dropdown" aria-expanded="false">
                <h5 class="my-0 py-0">
                  <i class="bi bi-box-arrow-in-left mr-0" style="color: white; font-size: 19px"></i>Olá,
                  {{ username }}
                </h5>
              </div>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <a class="dropdown-item" [routerLink]="['/perfil']" routerLinkActive="router-link-active">Perfil</a>
                </li>
                <li><a class="dropdown-item" (click)="logout()">Sair</a></li>
              </ul>
            </div>
            <div class="nav-item dropdown">
              <button type="button" class="btn notification nav-link dropdown-toggle" data-bs-toggle="dropdown">
                <span><i class="bi bi-cart icons" style="color: white;"></i></span>
                <span class="
                  badge badge-md badge-circle badge-floating badge-danger
                  border-white
                ">{{ totalItem }}</span>
              </button>

              <div class="dropdown-menu dropdown-large" id="dropdown-basic" *ngIf="totalItem > 0"
                style=" max-height: 350px">
                <div class="row" *ngFor="let prod of produtos; let i = index">
                  <div class="col-md-3 col-lg-3 col-sm-3 p-0 m-0">
                    <img [src]="storageUrl + prod.product.images[0].name" class="produto-img ml-4" />
                  </div>

                  <div class="col-md-7 col-lg-7 col-sm-7 p-0 m-0">
                    <p class="title text-dark p-0 m-0" style="font-size: 15px; font-family: 'Inter-Semibold';">
                      {{prod?.qty}}x {{ prod.product.commercial_name | titlecase }}
                    </p>
                    <p *ngIf="prod.product.type_sale == 1">
                      {{
                      (prod.product.promotion_price != 0
                      ? prod.product.promotion_price
                      : prod.product.price) * prod.qty | currency: "BRL":true
                      }}
                    </p>
                    <p *ngIf="prod.product.type_sale == 2">
                      {{prod.product.spots * prod.qty}} Pontos
                    </p>

                    <p *ngIf="prod.product.type_sale == 3">
                      {{
                      (prod.product.promotion_price != 0
                      ? prod.product.promotion_price
                      : prod.product.price) * prod.qty | currency: "BRL":true
                      }} ou {{prod.product.spots * prod.qty}} Pontos
                    </p>
                  </div>

                  <div class="col-md-2 col-lg-2 col-sm-2 p-0 m-0">
                    <div class="col-md-12 col-lg-12 col-sm-12 trash_icons p-0" (click)="onDelete(i)">
                      <img class="m-3 trash p-0 m-0" width="16px" height="16px" src="./assets/icon/trash.svg"
                        alt="Card image cap" />
                    </div>
                  </div>
                  <hr class="p-0 m-0" />
                </div>
                <hr class="flex-fill">

                <div class="d-flex align-items-center justify-content-center">
                  <button class="btn confirm" routerLink="/cart" style="width: 14rem">
                    Ver carrinho ({{total | currency: "BRL":true}})
                  </button>
                </div>

              </div>

              <div class="dropdown-menu dropdown-large" id="dropdown-basic" *ngIf="totalItem <= 0">
                <p class="text-center m-0 p-0">Carrinho Vazio</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <div class="position-relative" style="background-color: #a9b394;">
    <div class="container ">
      <div class="d-flex align-items-center">
        <div class="py-3" (mouseover)="hover = true">
          <p class="m-0 mr-4" style="font-weight: 600;"> <i class="fas fa-bars"></i>
            Todas as Seções</p>
        </div>
        <div class="d-flex align-items-center gap-3 position-relative">
          <ng-container *ngFor="let item of category.slice(0,6)">
            <div class="position-relative">
              <p (mouseenter)="hoverItem = item" (mouseenter)="hover= false" class="mb-0 mr-4">{{item.name}}</p>

              <div *ngIf="hoverItem == item" (mouseenter)="hoverItem = item" (mouseleave)="hoverItem = null"
                class="position-absolute top-1 start-0" style="z-index: 1000; margin-top: 26px;">
                <div class="classe">
                  <ng-container *ngFor="let child of item.children">
                    <p (click)="navigateCategory(item.id, child.name)" class="dropdown-item p-2 m-0"
                      style="cursor: pointer;">
                      {{child.name}}
                    </p>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>


      </div>
      <div *ngIf="hover" (mouseover)="hover = true" (mouseleave)="hover = false"
        class="row position-absolute top-0 start-0 mt-5 classe" style="z-index: 1000;">
        <ng-container *ngFor="let item of category">
          <div class="col-md-6 py-2 px-3">
            <p class="mb-1" style="font-weight: 600;">{{item.name}}</p>
            <ng-container *ngFor="let child of item.children">
              <a (click)="navigateCategory(item.id, child.name)">
                <p class="dropdown-item px-1 m-0">{{child.name}}</p>
              </a>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>