import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-skeleton',
  templateUrl: './loading-skeleton.component.html',
  styleUrls: ['./loading-skeleton.component.scss']
})
export class LoadingSkeletonComponent {


  public skeletonTheme = {
    'display': 'block',
    'width': '40%',
    'background-color': '#ddd'
  }

  constructor() { }


}
