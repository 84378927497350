import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AuthStorage } from 'src/app/shared/storage/auth.storage';
import { CartService } from 'src/app/services/cart.service';
import { Events } from 'src/app/shared/services/event/events.service';
import { LOAD_PROFILE } from 'src/app/shared/constants/events.constant';
import { Category } from 'src/app/models/category.model';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SettingsService } from 'src/app/services/settings.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent
  implements OnInit, OnDestroy {
  @Input('category') category: Array<Category> = []
  @Input('settings') settings: any

  public isCollapsed = true;
  public show = true;

  public arr: string[] = [];
  terms: string[] = ['', '', ''];

  closeResult: string;
  totalItem: number = 0;
  navCategory: Array<Category> = [];
  allCategorys: Array<Category> = [];
  storageUrl: string = environment.apiStorageUrl;

  produtos: any = [];
  total: number = 0;
  totalPoints: number = 0;

  public username: String | null = AuthStorage.getUser()?.name || null;

  menuSelecionado = '';
  drops = [];
  dropsCategoryPoints = [];
  dropTodasCategory = [];

  ponto: any = 0;
  nifUser = AuthStorage.getUser()?.nif || null;

  hover: boolean = false
  hover1: boolean = false

  constructor(
    public toastr: ToastrService,
    private cartService: CartService,
    private events: Events,
    private router: Router,
    private localStorageService: LocalStorageService,
    private SettingsService: SettingsService,
    private spinner: NgxSpinnerService
  ) {
  }


  ngOnInit(): void {
    this.getCartProducts();
    this.getProducts();
  }




  getSettings() {
    this.spinner.show()
    this.SettingsService.getSettings().subscribe(
      (response: any) => {
        this.settings = response.data
        this.localStorageService.setItem("SETTINGS", this.settings)
      },
      (error: any) => console.error(error)
    )
    setInterval(() => {
      this.spinner.hide()
    }, 1000)
  }


  ngOnDestroy() {
    this.events.destroy(LOAD_PROFILE);
  }

  public isAuthenticated(): boolean {
    return AuthStorage.isAuthenticated();
  }

  navigateCategory(category: number, name: string[]) {
    this.router.navigate(['/category', { categoryID: JSON.stringify(category), breadcrumb: name },]).then(() => location.reload())
  }


  getCartProducts() {
    this.cartService.getProducts().subscribe((data) => {
      this.totalItem = data.length;
    });
  }

  getTotal(data: any) {
    let subs: number = 0;
    for (let item of data) {
      subs +=
        (item.product.promotion_price != 0
          ? item.product.promotion_price
          : item.product.price) * item.qty;
      this.total = subs;
    }
    console.log(this.total, "total de produtos")
  }

  onDelete(i: number) {
    this.produtos.splice(i, 1);
    this.cartService.setCartData(this.produtos);
    location.reload();
    this.getTotal(this.produtos);
  }

  getProducts() {
    const data = this.cartService.getCartData();
    this.produtos = data;
    // console.log(this.produtos);
    this.produtos && this.getTotal(this.produtos);

  }

  public logout() {
    AuthStorage.clear();
    this.localStorageService.removeItem('USER-POINTS');
    this.events.publish(LOAD_PROFILE, {});
    this.router.navigateByUrl('/home').then(() => location.reload());
  }


  ngOnSearch(term: string): void {
    this.terms = [];
    this.arr.find((str) => {
      if (str.toLowerCase().search(term.toLowerCase()) >= 0)
        this.terms.push(str);
    });
  }

  onSearch(term: string): void {
    this.router.navigate(['search', { search: term }]);
  }

  search(event: any): void {
    switch (typeof event) {
      case 'string':
        this.onSearch(event);
        break;
      case 'object':
        this.onSearch(event.target.value);
        break;
      default:
        break;
    }
  }

  myRequest() {
    const swalParam = Swal.mixin({
      customClass: {
        confirmButton: 'btn confirm pl-4 pr-4',
      },
      buttonsStyling: false,
    });

    if (!this.isAuthenticated()) {
      swalParam.fire('Opss...', 'Você precisa está logado para visualizar seus pedidos!', 'info').then(() => {
        this.router.navigate(['/login']);
      })
    } else {
      this.router.navigate(['/perfil/pedidos']);
    }
  }

}
