<div class="cookie-notification">
  <div class="container container-cookies">
    <p class="lead">
      Utilizamos cookies para personalizar sua experiência. Saiba
      mais acessando a nossa
      <a href="{{apiStorage + policy}}" target="_blank">Política de Privacidade</a>
      ou leia nossos
      <a href="{{apiStorage + terms}}" target="_blank">termos de uso</a>.
    </p>
    <button class="btn btn-lg confirm my-sm-auto" (click)="onAcceptCookies()">
      Aceitar
    </button>
  </div>
</div>