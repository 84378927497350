import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericResultCommand } from '../commands/core/generic-result.command';
import { ServiceBase } from './core/service-base.service';

@Injectable({
  providedIn: 'root'
})
export class PointsService extends ServiceBase<any> {

  constructor(protected injector: Injector) {
    super("", injector);
  }

  getPoints(nif: String):Observable<GenericResultCommand> {
    return this.http.post<GenericResultCommand>(
      `${this.apiUrl}/user-balance`, { cpf: nif }
    );
  }

  forgotPassword(email: string):Observable<GenericResultCommand> {
    return this.http.post<GenericResultCommand>(
      `${this.apiUrl}/forgot-password-donuz`, { email: email }
    );
  }

}
