<nav class="nav-mob navbar navbar-expand-lg navbar-light bg-white ">
    <div class="container-fluid">
        <button class="navbar-toggler text-white" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
            aria-label="Toggle navigation" (click)="setNotScrool();">
            <span class="text-white navbar-toggler-icon"></span>
        </button>
        <a routerLink="/home" class="navbar-brand text-white"><img [src]="settings?.logo_url"></a>
        <div class="navbar-nav">
            <div class="d-flex align-items-center">
                <div class="nav-item mr-2">
                    <a class="nav-link nav-link-icon text-black" (click)="myRequest()">
                        <h5 style="cursor: pointer; font-size: 0.825rem; color: #000; margin: 0;" class="fontNav">
                            <i class="bi bi-bag mr-2" style="font-size: 16px; color: #000"></i>
                        </h5>
                    </a>
                </div>
                <div *ngIf="!isAuthenticated()" class="nav-item mr-2">
                    <div class="nav-link">
                        <a routerLink="/login" routerLinkActive="active" class="nav-link-icon text-black"> <i
                                class="bi bi-person" style="font-size: 20px; color: #000"></i> </a>
                    </div>
                </div>
                <div class="nav-item dropdown">
                    <a type="button" routerLink="/cart" routerLinkActive="active" class="btn notification nav-link">
                        <span><i class="bi bi-cart" style="font-size: 20px; color: #000"></i></span>
                        <span class="
                badge badge-md badge-circle badge-floating badge-danger
                border-white
                " style="top: -18px; right: -10px;background-color: red; color:white;">{{totalItem}}</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="navbar-nav" style="width: 100%;">
            <div class="nav-item">
                <a class="nav-link nav-link-icon">
                    <div class="input-group">
                        <input #searchTerm class="form-control button-search" type="search"
                            placeholder="O que você procura?" aria-label="Pesquisa" data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Digite o termo da pesquisa e pressione a tecla enter para pesquisar"
                            style="border-right: none;margin-left: auto;" />
                        <span class="input-group-text">
                            <svg xmlns="http://www.w3.org/2000/svg" (click)="search(searchTerm.value)" width="16"
                                height="16" fill="currentColor" class="bi bi-search"
                                style="cursor: pointer; color: var(---color-white);" viewBox="0 0 16 16">
                                <path
                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z">
                                </path>
                            </svg>
                        </span>
                    </div>
                </a>
            </div>
        </div>
        <div class="collapse navbar-collapse overflow-auto" id="navbarNavDropdown">
            <ul class="navbar-nav p-2">
                <div class="d-flex align-items-center justify-content-between">
                    <div *ngIf="!isAuthenticated()" class="nav-item">
                        <div [routerLink]="['/login']">
                            <a class="nav-link nav-link-icon text-black">
                                <h5 style="cursor: pointer; font-family:Inter-Bold" class="btn ">Entrar</h5>
                            </a>
                        </div>
                    </div>
                    <div *ngIf="!isAuthenticated()" class="nav-item">
                        <div [routerLink]="['/register']">
                            <a class="nav-link nav-link-icon text-black">
                                <h5 style="cursor: pointer; font-family:Inter-Bold" class="btn ">Cadastrar</h5>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="accordion" [id]="'auth'" *ngIf="isAuthenticated()">
                    <div class="accordion-item">
                        <h2 class="accordion-header" [id]="'headingAuth'">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#collapseOneAuth'" aria-expanded="true"
                                [attr.aria-controls]="'collapseOneAuth'">
                                Olá, {{username}}
                            </button>
                        </h2>
                        <div [id]="'collapseOneAuth'" class="accordion-collapse collapse"
                            [attr.aria-labelledby]="'headingAuth'" [attr.data-bs-parent]="'#accordionExample'">
                            <div class="accordion-body">
                                <ng-container *ngIf="isAuthenticated();">

                                    <a class="nav-link" routerLinkActive="active" routerLink="/perfil"
                                        style="font-family: Inter-Bold; cursor: pointer;">
                                        Perfil
                                    </a>
                                    <a class="nav-link" (click)="logout()"
                                        style="font-family: Inter-Bold; cursor: pointer;">
                                        Sair
                                    </a>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion" [id]="'accordionExample'+i"
                    *ngFor="let todasCategorias of category let i = index">
                    <div class="accordion-item">
                        <h2 class="accordion-header" [id]="'headingOne'+i">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#collapseOne'+i" aria-expanded="true"
                                (click)="acordeonClicked(i)" [attr.aria-controls]="'collapseOne'+i">
                                {{todasCategorias.name}}
                            </button>
                        </h2>
                        <div [id]="'collapseOne'+i" class="accordion-collapse collapse"
                            [attr.aria-labelledby]="'headingOne'+i" [attr.data-bs-parent]="'#accordionExample'+i">
                            <div class="accordion-body">
                                <ng-container *ngIf="todasCategorias.children">
                                    <a *ngIf="todasCategorias.children.length == 0" class="nav-link"
                                        routerLinkActive="active" (click)="naveCategory(todasCategorias.id!)"
                                        style="font-family: Inter-Bold; cursor: pointer;">
                                        {{ todasCategorias.name }}
                                    </a>
                                </ng-container>
                                <div *ngFor="let sub of todasCategorias.children" class="">
                                    <ng-container *ngIf="todasCategorias.children">
                                        <div *ngIf="sub.children">
                                            <a *ngIf="sub.children.length != 0" routerLinkActive="active"
                                                (click)="naveCategory(sub.id!)"
                                                style="cursor: pointer; text-transform: capitalize; font-family: Inter-SemiBold; "
                                                class="" (click)="(false)">{{ sub.name }}
                                            </a>
                                            <div *ngFor="let sub2 of sub.children">
                                                <a routerLinkActive="active" (click)="naveCategory(sub2.id!)" class=""
                                                    style="font-size: 14px; cursor: pointer;">
                                                    {{ sub2.name}}
                                                </a>
                                            </div>
                                        </div>
                                        <div *ngIf="sub.children">
                                            <a *ngIf="sub.children.length == 0" routerLinkActive="active"
                                                (click)="naveCategory(sub.id!)" class=""
                                                style="cursor: pointer; text-transform: capitalize; font-family: Inter-SemiBold; "
                                                class="">
                                                {{sub.name}}
                                            </a>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ul>
        </div>
    </div>
</nav>