<a [href]="'product-details;produto='+property.id">
    <div class="card card-service">
        <div *ngIf="property?.promotion_price!=0" class="price" class="desconto text-center">
            {{property?.promotion_percent | number: '1.0-0'}}% OFF</div>
        <div style="display: flex;" class="image-product">
            <img *ngIf="image?.name != null" class="card-img " [src]="imgUrl + image?.name" alt="Card image cap">
            <img *ngIf="image?.name == null" class="card-img" src="https://dummyimage.com/400x400/d1d1d1/ffffff.png"
                alt="Card image cap">
        </div>

        <div class="card-detalhe">
            <button class="btn btn-block confirm" [disabled]="activeButton">{{buttonAvailable}}</button>
        </div>

        <div class="card-body px-0 pb-0">
            <div class="textos-card">
                <div class="mx-1 mt-2">
                    <p class=" font-weight-bolder commercial_name">{{property?.commercial_name}}</p>
                </div>
                <!-- <div class="description">
                    <span>
                        {{ property?.description}}
                    </span>
                </div> -->
                <div class="price-dtl">
                    <div *ngIf="property?.promotion_price!=0 && property?.type_sale!=2" class="row">
                        <div class="col">
                            <p class="price" class="price-salle">
                                {{property?.price|currency:"BRL":true}}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="property?.type_sale == 2">
                        <div class="price-point">
                            {{property?.spots | number: '1.0-0'}} Pontos
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p *ngIf="property?.promotion_price!=0 && property?.type_sale!=2" class="price">
                                {{property?.promotion_price|currency:"BRL":true}}
                            </p>
                            <p *ngIf="property?.promotion_price == 0 && property?.type_sale!=2" class="price">
                                {{property?.price|currency:"BRL":true}}
                            </p>
                        </div>
                        <div *ngIf="property?.type_sale == 3" class="col-12">
                            <p>
                                <span style="font-size: 1.1rem">
                                    ou {{property?.spots |number: '1.0-0'}} Pontos
                                </span>
                            </p>
                        </div>
                    </div>
                    <div >
                        <small >
                       
                                <p class="info-payment"> 
                                        <ng-container>
                                           À vista com <strong>Boleto</strong> ou em até {{parcela_disponivel}}
                                        </ng-container>
                                </p>
                        </small>
                    </div>
                    <div class="card-title">
                        <p class="paragrafo" *ngIf="property.score_number > 0">
                            <small>Ganhe: </small>
                            <ng-container
                                *ngIf="property?.score_number > 999; then thenTemplate; else elseTemplate">
                            </ng-container>
                            <ng-template #thenTemplate>
                                <span style="font-size: 1rem;">{{property?.score_number}} Pontos</span>
                            </ng-template>
                            <ng-template #elseTemplate>
                                <span>{{property?.score_number}} Pontos</span>
                            </ng-template>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</a>
<ng-container *ngIf="property && property.is_enabled == 1">
</ng-container>
