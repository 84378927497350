import { Component, OnInit, Input } from '@angular/core';
import { Products } from 'src/app/models/products.model';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @Input('products') products?: Array<Products> = [];
  @Input('config') config?: any = null;

  constructor() { }

  ngOnInit(): void {
  }

}
