import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomePageService {

  headers = new HttpHeaders({
    'access-control-allow-origin': '*',
    'Content-Type': 'application/json',
    'app': environment.app
  });

  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();

  constructor(
    private http: HttpClient
  ) {
  }


  getDataApp() {
    return this.http.get(`${environment.apiUrl}/home`, { headers: this.headers }).subscribe(
      (data: any) => this.dataSubject.next(data)
    );
  }

}
