import { Injectable } from '@angular/core';

import { ToastrService as Toast } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor(private toast: Toast) { }

  default(title: string = null, message: string = null): void {
    this.toast.show(`
        <span class="alert-icon ni ni-bell-55" data-notify="icon"></span>
        <div class="alert-text">
          <span class="alert-title" data-notify="title">${title}</span>
          <span data-notify="message">${message}</span>
        </div>
        `,
      '',
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-center',
        toastClass: "ngx-toastr alert alert-dismissible alert-default alert-notify",
      }
    );
  }

  danger(title: string = null, message: string = null): void {
    this.toast.show(`
      <span class="alert-icon ni ni-bell-55" data-notify="icon"></span>
      <div class="alert-text">
        <span class="alert-title" data-notify="title">${title}</span>
        <span data-notify="message">${message}</span>
      </div>
      `,
      '',
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-center',
        toastClass: "ngx-toastr alert alert-dismissible alert-danger alert-notify",
      }
    );
  }

  error(title: string = null, message: string = null): void {
    this.toast.error(message, title, {
      closeButton: true,
      progressBar: false,
      positionClass: "toast-top-right",
      timeOut: 5000,
      extendedTimeOut: 1000,
    })
  }

  success(title: string = null, message: string = null): void {
    this.toast.show(`
      <span class="alert-icon ni ni-bell-55" data-notify="icon"></span>
      <div class="alert-text">
        <span class="alert-title" data-notify="title">${title}</span>
        <span data-notify="message">${message}</span>
      </div>
      `,
      '',
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-center',
        toastClass: "ngx-toastr alert alert-dismissible alert-success alert-notify",
      }
    );
  }

  warning(title: string = null, message: string = null): void {
    this.toast.show(`
        <span class="alert-icon ni ni-bell-55" data-notify="icon"></span>
        <div class="alert-text">
          <span class="alert-title" data-notify="title">${title}</span>
          <span data-notify="message">${message}</span>
        </div>
        `,
      '',
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-center',
        toastClass: "ngx-toastr alert alert-dismissible alert-warning alert-notify",
      }
    );
  }

  info(title: string = null, message: string = null): void {
    this.toast.show(`
        <span class="alert-icon ni ni-bell-55" data-notify="icon"></span>
        <div class="alert-text">
          <span class="alert-title" data-notify="title">${title}</span>
          <span data-notify="message">${message}</span>
        </div>
        `,
      '',
      {
        timeOut: 3000,
        closeButton: true,
        enableHtml: true,
        tapToDismiss: false,
        titleClass: 'alert-title',
        positionClass: 'toast-top-center',
        toastClass: "ngx-toastr alert alert-dismissible alert-info alert-notify",
      }
    );
  }

}
