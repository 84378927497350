<ng-container *ngIf="property && property.is_enabled == 1">
    <a [href]="'product-details;produto='+property.id">
        <div class="card card-service">
            <div class="desconto text-center">{{property?.promotion_percent | number: '1.0-0'}}% OFF
            </div>
            <div style="display: flex;">
                <img *ngIf="image?.name != null" class="card-img " [src]="imgUrl + image?.name" alt="Card image cap">
                <img *ngIf="image?.name == null" class="card-img" src="https://dummyimage.com/400x400/d1d1d1/ffffff.png"
                    alt="Card image cap">
            </div>
            <div class="card-detalhe">
                <button class="btn btn-block" [disabled]="activeButton">{{buttonAvailable | uppercase}}</button>
            </div>
            <div class="card-body card-body-andamento px-0 pb-0">
                <div class="textos-card">
                    <div class="mt-2 mb-2 tamanho">
                        <p class="mb-3 font-weight-bolder">{{property?.commercial_name}}</p>
                    </div>
                    <div class="text-center">
                        {{ property?.brand || 'marca' }}
                    </div>
                    <div class="price-dtl">
                        <div class="row">
                            <div class="col-6">
                                <p class="price-salle">{{property?.price|currency:"BRL":true}}</p>
                            </div>
                            <div class="col-6">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="price" *ngIf="property?.type_sale != 2">
                                    {{property?.promotion_price|currency:"BRL":true}}</p>
                                <p class="price" *ngIf="property?.type_sale == 2">
                                    {{property?.spots | number: '1.0-0'}} Pontos
                                </p>
                            </div>
                            <div class="col-12">
                                <p *ngIf="property?.type_sale == 3">
                                    <span style="font-size: 1.1rem">
                                        ou {{property?.spots | number: '1.0-0'}} Pontos
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <div *ngIf="property?.type_sale != 2">
                                <small *ngFor="let payment of property?.payment_methods">
                                    <ng-container *ngIf="payment.description == 'Cartão de Credito'">
                                        <p *ngIf="property?.payment_condition"> em {{property?.payment_condition}}</p>
                                    </ng-container>
                                </small>
                            </div>
                        </div>
                        <div class="card-title">
                            <p class="paragrafo" *ngIf="property?.score_number > 0">
                                <small>Ganhe: </small>
                                <span>{{property?.score_number | number: '1.0-0'}} Pontos</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
</ng-container>