import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Products } from '../models/products.model';
import { ToastUI } from '../shared/ui/toast.ui';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  /**
   * CART
   */
  placeHolder = [];
  cartItems = new BehaviorSubject<any>([])

  constructor(private toastUi: ToastUI) {
    const ls = this.getCartData()
    if (ls) {
      this.cartItems.next(ls)
    }
  }

  /**
   * CART
   */

  addItem(product: any) {
    const ls = this.getCartData()

    let exist: any;

    if (ls)
      exist = ls.find((items: any) => {
        return items?.product?.id === product.product.id
      })
    let quantity = parseInt(product.product.quantity)

    if (exist) {
      if (exist.qty <= quantity - 1) {
        exist.qty++ //aumenta a quantidade
        this.setCartData(ls)
      }
    } else {
      if (ls) {
        const newData = [...ls, product];
        this.setCartData(newData)
      }
      else {
        this.placeHolder.push(product)
        this.setCartData(this.placeHolder)
      }
    }
  }

  setCartData(data: any) {
    sessionStorage.setItem('cart', JSON.stringify(data))
    this.cartItems.next(this.getCartData())
  }

  getCartData() {
    return JSON.parse(sessionStorage.getItem('cart'))
  }

  getProducts() {
    return this.cartItems.asObservable()
  }

  cleanCart() {
    sessionStorage.clear()
    location.reload()
  }


}
