import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-category',
  templateUrl: './nav-category.component.html',
  styleUrls: ['./nav-category.component.scss']
})
export class NavCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
