import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-whatsapp-botao',
  templateUrl: './whatsapp-botao.component.html',
  styleUrls: ['./whatsapp-botao.component.scss']
})
export class WhatsappBotaoComponent implements OnInit {
  settings: any = [];

  constructor(
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.getSettings()
  }

  getSettings() {
    this.settingsService.getSettings().subscribe(
      (response: any) => {
        this.settings = response.data        
      },
      (error: any) => console.log("error", error)
    )
  }

}
