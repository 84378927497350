<ng-template #classic3 let-c="close" let-d="dismiss">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">Modal title</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
          ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="c('Close click')">Close</button>
        <button type="button" class="btn bg-color-primary">Save changes</button>
      </div>
    </div>
  </ng-template>