import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericResultCommand } from 'src/app/commands/core/generic-result.command';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Input()
  category: any;

  @Output() gotProducts: EventEmitter<any> = new EventEmitter();

  products: any;

  constructor(private productService: ProductsService) { }

  ngOnInit(): void {
  }

  naveCategory(id){
    this.productService.getByCategory(id)
    .then((produtos:GenericResultCommand)=> {
      this.products = produtos.data.data
    })
    .then(() => this.gotProducts.emit(this.products))
    .catch(err => console.error(err.message));
  }

  acordeonClicked(id): void {
    let accordions = <HTMLCollectionOf<Element>> document.getElementsByClassName('accordion-collapse');
    for (let index = 0; index < accordions.length; index++) {
      const element = <Element> accordions[index];
      if (element.id !== `collapseOne${id}`)
        element.classList.remove('show');
    }
  }

}
