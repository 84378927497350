import { Injectable, Injector } from '@angular/core';
import { GenericResultCommand } from '../commands/core/generic-result.command';
import { HeaderUtil } from '../utils/header.util';
import { ServiceBase } from './core/service-base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends ServiceBase<any> {

  constructor(protected injector: Injector) {
    super("", injector);
  }

  getProducts(page: number = 1): Promise<GenericResultCommand> {
    return this.http.get<GenericResultCommand>(
      `${this.apiUrl}/products?page=${page}`, { headers: HeaderUtil.get() }
    ).toPromise();
  }


  showProducts(id): Promise<GenericResultCommand> {
    return this.http.get<GenericResultCommand>(
      `${this.apiUrl}/products/${id}`, { headers: HeaderUtil.get() }
    ).toPromise();
  }

  getCategory(): Promise<GenericResultCommand> {
    return this.http.get<GenericResultCommand>(
      `${this.apiUrl}/sections`, { headers: HeaderUtil.get() }
    ).toPromise();
  }

  getByCategory(id: number): Promise<GenericResultCommand> {
    return this.http.get<GenericResultCommand>(
      `${this.apiUrl}/products?section=${id}`, { headers: HeaderUtil.get() }
    ).toPromise();
  }

  getByCategoryHome(): Promise<GenericResultCommand> {
    return this.http.get<GenericResultCommand>(
      `${this.apiUrl}/sections-home`, { headers: HeaderUtil.get() }
    ).toPromise();
  }

  getBySearch(term: string): Promise<GenericResultCommand> {
    return this.http.get<GenericResultCommand>(
      `${this.apiUrl}/products?search=${term}`, { headers: HeaderUtil.get() }
    ).toPromise();
  }

  orderProduct(body): Promise<any> {
    return this.http.post<any>(
      `${this.apiUrl}/orders`, body
    ).toPromise();
  }

}


