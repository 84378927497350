import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ClipboardModule } from 'ngx-clipboard';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoadingSkeletonComponent } from './loading-skeleton/loading-skeleton.component';
import { ValidationMessageComponent } from './validation-message/validation-message.component';
import { CardComponent } from './card/card.component';
import { CardeSalleComponent } from './carde-salle/carde-salle.component';
import { NavCategoryComponent } from './nav-category/nav-category.component';
import { CardSemSalleComponent } from './card-sem-salle/card-sem-salle.component';
import { ModalComponent } from './modal/modal.component';
import { NavmobileComponent } from './navmobile/navmobile.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { WhatsappBotaoComponent } from './whatsapp-botao/whatsapp-botao.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { TotalDotsComponent } from './total-dots/total-dots.component';
import { CardMobileComponent } from './card-mobile/card-mobile.component';
import { SliderComponent } from './slider/slider.component';
import { CnpjPipe } from "src/app/shared/pipes/cnpj.pipe";

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgxSpinnerModule } from 'ngx-spinner';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    LoadingSkeletonComponent,
    ValidationMessageComponent,
    CardComponent,
    CardeSalleComponent,
    NavCategoryComponent,
    CardSemSalleComponent,
    ModalComponent,
    NavmobileComponent,
    BreadcrumbComponent,
    WhatsappBotaoComponent,
    SideMenuComponent,
    TotalDotsComponent,
    CardMobileComponent,
    SliderComponent,
    CnpjPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    ClipboardModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    SwiperModule,
    NgxSpinnerModule
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    LoadingSkeletonComponent,
    ValidationMessageComponent,
    CardComponent,
    CardeSalleComponent,
    NavCategoryComponent,
    CardSemSalleComponent,
    ModalComponent,
    NavmobileComponent,
    BreadcrumbComponent,
    WhatsappBotaoComponent,
    SideMenuComponent,
    TotalDotsComponent,
    CardMobileComponent,
    SliderComponent,
    CnpjPipe
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
})
export class SharedComponentsModule {}
