import { Injectable, Injector } from '@angular/core';
import { HeaderUtil } from '../utils/header.util';
import { ServiceBase } from './core/service-base.service';
import { GenericResultCommand } from '../commands/core/generic-result.command';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class PagseguroService extends ServiceBase<any> {
  constructor(protected injector: Injector) {
    super('', injector);
  }

  getSession(): Observable<any> {
    return this.http.get(`${this.apiUrl}/pagseguro-session`);
  }

  getPublicKey(): Promise<GenericResultCommand> {
    return this.http.get(`${this.apiUrl}/public-key`).toPromise();
  }

  obterParcelasDisponiveis(payload: any): Promise<GenericResultCommand> {
    return this.http
      .post<GenericResultCommand>(
        `${this.apiUrl}/pagseguro-installments`,
        payload,
        { headers: HeaderUtil.get() }
      )
      .toPromise();
  }
}
