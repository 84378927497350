import swal from "sweetalert2";
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ToastUI {


    constructor() { }

    basicSwal(title: string, text: string, confirmButtonClass = 'button-confirm btn bg-color-primary') {
        swal.fire({
            title: title,
            text: text,
            buttonsStyling: false,
            confirmButtonClass: confirmButtonClass
        });
    }

    infoSwal(title: string, text: string, confirmButtonClass = 'button-confirm btn bg-color-primary') {
        swal.fire({
            title: title,
            text: text,
            type: "info",
            buttonsStyling: false,
            confirmButtonClass: confirmButtonClass
        });
    }

    successSwal(title: string, text: string,  confirmButtonClass = 'button-confirm btn bg-color-primary') {
        swal.fire({
            title: title,
            text: text,
            type: "success",
            buttonsStyling: false,
            confirmButtonClass: confirmButtonClass,
            timer: 6000
        })
    }
    
    warningEmail(title: string, text: string, confirmButtonClass = 'button-confirm btn bg-color-primary') {
        swal.fire({
            title: title,
            text: text,
            html: `
            <div>
                <p>${text}</p>
                <div class="d-flex justify-content-center">
                <a href="/register" class="btn bg-color-primary">Cadastrar</a>
                <a href="/login" class="btn btn-outline-dark">Cancelar</a>
                </div>
            </div>            
            `,
            type: "warning",
            showConfirmButton: false

        });
    }

    warningSwal(title: string, text: string, confirmButtonClass = 'button-confirm btn bg-color-primary') {
        swal.fire({
            title: title,
            text: text,
            type: "warning",
            buttonsStyling: false,
            confirmButtonClass: confirmButtonClass

        });
    }

    questionSwal(title: string, text: string, confirmButtonClass = 'button-confirm btn bg-color-primary') {
        swal.fire({
            title: title,
            text: text,
            type: "question",
            buttonsStyling: false,
            confirmButtonClass: confirmButtonClass
        });
    }

    toastAlert(title: string, text: string,) {
        swal.fire({
            type: "success",
            text: `${text}`,
            title: title,
            toast: true,
            position: "bottom-right",
            timer: 30000,
            showConfirmButton: false,
        });
    }

    addProdToCart(title: string, text: string) {
        swal.fire({
            title: title,
            text: text,
            type: "success",
            showConfirmButton: false,
            timer: 3000
        });
    }

}
