import { Component, Input, OnInit } from '@angular/core';
import { Products } from 'src/app/models/products.model';
import { environment } from 'src/environments/environment';
import { Image } from 'src/app/models/products.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carde-salle',
  templateUrl: './carde-salle.component.html',
  styleUrls: ['./carde-salle.component.scss'],
})
export class CardeSalleComponent implements OnInit {
  @Input('property') property?: Products;
  image?: Image;
  percentual: Number;
  buttonAvailable: string;
  activeButton: boolean;
  imgUrl: String = environment.apiStorageUrl;
  quantidade: number = 0;
  constructor(private route: Router) {}

  ngOnInit(): void {
    this.image = this.property?.images[0];
    //this.quantidade = parseInt(this.property.quantity);
    this.removeDecimal();
    this.checkAvailability();
  }

  removeDecimal() {
    this.percentual = Math.trunc(Number(this.property?.discount));
  }

  navigationProductDetails(product) {
    this.route.navigate([
      '/product-details',
      { produto: JSON.stringify(product) },
    ]);
  }

  checkAvailability() {
    if (this.quantidade <= 0) {
      this.buttonAvailable = 'Indisponível';
      this.activeButton = false;
    } else {
      this.buttonAvailable = 'Comprar';
      this.activeButton = false;
    }
  }
}
