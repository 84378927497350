import { Component, OnInit } from '@angular/core';
import { GenericResultCommand } from 'src/app/commands/core/generic-result.command';
import { PointsService } from 'src/app/services/points.service';
import { AuthStorage } from 'src/app/shared/storage/auth.storage';

@Component({
  selector: 'app-total-dots',
  templateUrl: './total-dots.component.html',
  styleUrls: ['./total-dots.component.scss']
})
export class TotalDotsComponent implements OnInit {
  ponto: any
  nifUser = AuthStorage.getUser().nif
  constructor(
    private pontos: PointsService
  ) { }

  ngOnInit(): void {
    this.getDots(this.nifUser)
  }

  getDots(nif:string) {
    this.pontos.getPoints(nif).subscribe((data: GenericResultCommand) => {
      this.ponto = data.data
    })
  }

}
