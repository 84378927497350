<footer class="pb-3 desktop">
  <div class="card2">
    <div class="container">
      <div class="d-flex container-footer mb-4">
        <div class="col-xl-4 col-lg-3 col-sm-6 col-xs-12 logo_text pr-xl-5">
          <a class="logo">
            <img src={{settings?.logo_url}} alt={{settings?.full_name}} (click)="scrollNavigation()"
              class="mb-2 logo-company">
          </a>

          <div class="subtext">
            <p [innerText]="settings?.footer"></p>
            <!-- <div class="icons-links">
                <li class="link_settings" *ngIf="settings?.instagram_user != null">
                  <a target="_blank" [href]="settings?.instagram_url">
                    <span>
                      <i class="bi bi-instagram mr-2"></i>
                    </span>
                    {{settings?.instagram_user}}
                  </a>
                </li>

                <li class="link_settings" *ngIf="settings?.facebook_url != null">
                  <a target="_blank" [href]="settings?.facebook_url">
                    <span>
                      <i class="bi bi-facebook mr-2"></i>
                    </span>
                    {{settings?.facebook_user}}
                  </a>
                </li>

                <li class="link_settings" *ngIf="settings?.maps != null">
                  <a target="_blank" [href]="settings?.maps" class="address">
                    <span>
                      <i class="bi bi-geo-alt mr-2"></i>
                    </span>
                    {{settings?.address}}, {{settings?.number}}. {{settings?.district}}. {{settings?.city?.title}} -
                    {{settings?.city?.state?.letter}}, {{settings?.zip_code}}
                  </a>
                </li>

              </div> -->
            <div class="endereço">
              <li class="link_settings list-decoration ">
                <a target="_blank" [href]="settings?.maps" class="address">
                  <span>
                    <i class="bi bi-geo-alt mr-2"></i>
                  </span>
                  {{settings?.address}}, {{settings?.number}}. {{settings?.district}}. {{settings?.city?.title}} -
                  {{settings?.city?.state?.letter}}, {{settings?.zip_code}}
                </a>
              </li>
            </div>
          </div>



        </div>

        <div>

          <div class="d-flex  footer-sections">

            <div class="col-sm-5 col-md-4 col-xs-12 ">

              <h5 class="heading">Faq</h5>

              <a target="_blank" routerLink="/faq">
                <li class="list-decoration">Dúvidas Frequentes</li>
              </a>

              <h5 class="heading">Transparência</h5>
              <ul class="card-text">
                <a [href]="apiStorage + settings?.privacy_policy" target="_blank">
                  <li>Política de Privacidade</li>
                </a>
                <a [href]="apiStorage + settings?.terms" target="_blank">
                  <li>Termos de uso</li>
                </a>


              </ul>
            </div>

            <div class="col-sm-5 col-md-4 col-xs-12 mapa-site-footer">
              <h5 class="heading">Mapa do site</h5>
              <a (click)="navigationMapSite(1)">
                <li class="list-decoration">Início</li>
              </a>
              <a (click)="navigationMapSite(3)">
                <li class="list-decoration">Minha Conta</li>
              </a>
              <a (click)="navigationMapSite(4)">
                <li class="list-decoration">Meus Pedidos</li>
              </a>
              <a (click)="navigationMapSite(5)">
                <li class="list-decoration">Carrinho de Compras</li>
              </a>



              <!-- <ul class="imgOpacity">
                          <li *ngFor="let selo of seloArray">
                            <img src={{selo?.image_url}} alt={{selo?.name}} class="mb-2" style="cursor: pointer; width: 150px; height: 150px;">
                          </li>
                        </ul> -->
            </div>

            <div class=" col-sm-5 col-md-4 col-xl-6 col-xs-12">
              <h5 class="heading">Acesso interno</h5>
              <a href="https://www.google.com/gmail/" target="_blank">
                <li class="list-decoration">Webmail</li>
              </a>
              <a href="http://mercaecommerce.com/login" target="_blank">
                <li class="list-decoration">Painel Administrativo</li>
              </a>

              <h5 class="heading">Redes sociais</h5>

              <div class="icons">
                <a target="_blank" [href]="settings?.instagram_url">
                  <span>
                    <i class="bi bi-instagram mr-2"></i>
                  </span>
                </a>

                <a target="_blank" [href]="settings?.facebook_url">
                  <span>
                    <i class="bi bi-facebook mr-2"></i>
                  </span>
                  {{settings?.facebook_user}}
                </a>

                <a target="_blank" href="https://api.whatsapp.com/send?phone=55{{settings?.phone}}&text=Podem%20me%20ajudar?">
                  <span>
                    <i class="bi bi-whatsapp"></i>
                  </span>
                </a>
              </div>
            </div>


          </div>

          <div class="container-geral col-10">
            <div>
              <h2 class="fontTitle mb-0 h5 title_section">Formas de pagamento</h2>
              <div class="formasdepagamento">
                <div class="d-flex align-items-center">
                  <li class="list-decoration icon-payment" *ngFor="let method_payment of paymentMethodsArray">
                    <img [src]="apiStorage + method_payment?.icon" alt="">                    
                  </li>                  
                </div>
              </div>
            </div>
            <div
              class="redesSociaisSelo mr-4 col-md-5 col-sm-12 col-xl-6 d-flex align-items-center justify-content-center">
              <div class="icons-links ">

                <div class="list-decoration icons_title">
                  <h2 class="fontTitle mb-0 h5 title_section">Compra Segura</h2>

                  <div class="d-flex flex-row">
                    <span *ngFor="let stamp of seloArray">
                      <img [src]="apiStorage + stamp" alt="">  
                    </span>
                  </div>
                 
                </div>



              </div>
            </div>

          </div>

        </div>







      </div>


      <div class="divider mb-2"> </div>


      <div class="row" style="font-size:10px;">
        <div class="col-md-8 col-sm-12 col-xs-12">
          <div class="pull-left">
            <p style="float: left; font-size: 14px;" class="subtext"> Copyright&#169; {{anoPresente}}
              {{settings?.full_name}},
              CNPJ: {{settings?.nif | cnpj}}.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
          <div class="pull-right ">
            <a href="https://dix.digital/" target="blank"
              style="display: flex; align-items: center; justify-content: end;">
              <p style="float: right; font-size: 14px; margin-right: 1rem;" class="subtext"> Desenvolvido por</p> <img
                src="./assets/logo/logoDixDigital.png" alt="" style="width:124px">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<footer class="mobile">
  <a class="logo">
    <img src={{settings?.logo_url}} alt={{settings?.full_name}} class="mb-2 logo-company">
  </a>
  <p [innerText]="settings?.footer"></p>
  <a target="_blank" [href]="settings?.maps" class="address">
    <span>
      <i class="bi bi-geo-alt mr-2"></i>
    </span>
    {{settings?.address}}, {{settings?.number}}. {{settings?.district}}. {{settings?.city?.title}} -
    {{settings?.city?.state?.letter}}, {{settings?.zip_code}}
  </a>

  <div class="footer_sections">
    <div class="section">
      <h5 class="heading">Faq</h5>

      <a target="_blank" routerLink="/faq">
        <li class="list-decoration">Dúvidas Frequentes</li>
      </a>
    </div>
    <div class="section">

      <h5 class="heading">Transparência</h5>
      <ul class="card-text">
        <a [href]="apiStorage + settings?.privacy_policy" target="_blank">
          <li>Política de Privacidade</li>
        </a>
        <a [href]="apiStorage + settings?.terms" target="_blank">
          <li>Termos de uso</li>
        </a>


      </ul>
    </div>
    <div class="section">

      <h5 class="heading">Mapa do site</h5>
      <a (click)="navigationMapSite(1)">
        <li class="list-decoration">Início</li>
      </a>
      <a (click)="navigationMapSite(3)">
        <li class="list-decoration">Minha Conta</li>
      </a>
      <a (click)="navigationMapSite(4)">
        <li class="list-decoration">Meus Pedidos</li>
      </a>
      <a (click)="navigationMapSite(5)">
        <li class="list-decoration">Carrinho de Compras</li>
      </a>
    </div>
    <div class="section">

      <h5 class="heading">Acesso interno</h5>
      <a href="https://www.google.com/gmail/" target="_blank">
        <li class="list-decoration">Webmail</li>
      </a>
      <a href="http://mercaecommerce.com/login" target="_blank">
        <li class="list-decoration">Painel Administrativo</li>
      </a>

      <h5 class="heading">Redes sociais</h5>

      <div class="icons">
        <a target="_blank" [href]="settings?.instagram_url">
          <span>
            <i class="bi bi-instagram mr-2"></i>
          </span>
        </a>

        <a target="_blank" [href]="settings?.facebook_url">
          <span>
            <i class="bi bi-facebook mr-2"></i>
          </span>
          {{settings?.facebook_user}}
        </a>

        <a target="_blank" href="https://api.whatsapp.com/send?phone=557199200-1317&text=Podem%20me%20ajudar?">
          <span>
            <i class="bi bi-whatsapp"></i>
          </span>
        </a>
      </div>
    </div>

    <div class="section">
      <div class="informations_corporations">
        <div class="">
          <div class="pull-left">
            <p style="float: left; font-size: 14px;" class="subtext"> Copyright&#169; {{anoPresente}}
              {{settings?.full_name}},
              CNPJ: {{settings?.nif | cnpj}}.
            </p>
          </div>
        </div>
        <div class="">
            <a href="https://dix.digital/" target="blank"
              style="display: flex; align-items: center;">
              <p style="float: right; font-size: 14px; margin-right: 1rem;" class="subtext"> Desenvolvido por</p> <img
                src="./assets/logo/logoDixDigital.png" alt="" style="width:124px">
            </a>
        </div>
      </div>
    </div>


  </div>

</footer>