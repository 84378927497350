<div id="card-list-group-component" class="tab-pane tab-example-result fade show active" role="tabpanel"
    aria-labelledby="card-list-group-component-tab">
    <div class="card text-left">
        <h5 class="card-title p-3 mb-0 bg-color-primary" style="color:white;border-radius: 5px 5px 0px 0px;">
            CATEGORIAS</h5>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">EPI e Segurança</li>
            <li class="list-group-item">Serviços</li>
            <li class="list-group-item">Casa/Lazer</li>
        </ul>
    </div>
</div>