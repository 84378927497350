import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { AuthStorage } from '../shared/storage/auth.storage';
import { GenericResultCommand } from 'src/app/commands/core/generic-result.command';

@Injectable()
export class AuthorizeGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate() {
    const token = AuthStorage.getToken();

    if (!token) {
      this.router.navigate(['/login']);
      return false;
    }

    const result: GenericResultCommand = this.isTokenValid();
    if (result.error) {
      this.router.navigateByUrl('/login');
      return false;
    }

    return true;
  }

  private isTokenValid(): GenericResultCommand {
    const decodedToken = this.getDecodedToken();
    if (!decodedToken || !decodedToken.exp)
      return new GenericResultCommand(true);

    const expiresIn = decodedToken.exp;
    if ((Math.floor((new Date).getTime() / 1000)) >= expiresIn)
      return new GenericResultCommand(true, 'Sua sessão expirou. Faça login novamente');
    else
      return new GenericResultCommand(false);
  }

  private getDecodedToken(): any {
    const token = AuthStorage.getToken();
    if (!token)
      return null;

    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

}