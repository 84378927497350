import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentGuard } from './guards/cart.guard';
import { AuthorizeGuard } from "./guards/authorize.guard";
import { BrowserModule } from '@angular/platform-browser';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'login', loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule) },
  { path: 'register', loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule) },
  { path: 'category', loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule) },
  { path: 'search', loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule) },
  { path: 'product-details', loadChildren: () => import('./pages/product-details/product-details.module').then(m => m.ProductDetailsModule) },
  { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule) },
  { path: 'payment', loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),canActivate: [PaymentGuard] },
  { path: 'perfil', loadChildren: () => import('./pages/profile/profile-user/profile-user.module').then(m => m.ProfileUserModule), canActivate: [AuthorizeGuard] },
  { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule)},
  { path: 'promotion-products/:id', loadChildren: () => import('./pages/promotion-products/promotion-products.module').then(m => m.PromotionProductsModule) },
  { path: 'reset-password', loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: '**', loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule)},
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled'
  }),
  BrowserModule
],
  
  exports: [RouterModule]
})
export class AppRoutingModule { }
