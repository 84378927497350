import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PagseguroService } from "src/app/services/pagseguro.service";
import { HomePageService } from './services/home-page.service';

const COOKIES_KEY = 'COOKIES';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  showCookiesNotification: boolean = false;
  settings: any;
  categories: any;

  constructor(
    private localStorageService: LocalStorageService, 
    private pagSeguroService: PagseguroService,
    private homepageService: HomePageService) {

      this.homepageService.getDataApp()

      
  }
  
  ngOnInit(): void {

    this.homepageService.data$.subscribe((res:any)=> {
      if(res){
        this.settings = res.data.settings
        this.categories = res.data.sections

      }
    })

    let cookies = JSON.stringify(
      this.localStorageService.getPolyce(COOKIES_KEY)
    );
    switch (cookies) {
      case 'true':
        this.showCookiesNotification = true;
        break;
      case 'false':
        this.showCookiesNotification = false;
        break;
      default:
        this.showCookiesNotification = true;
        break;
    }
  }

  onScrollToTop() {
    window.scroll(0, 0);
  }

  onAcceptCookies(): void {
    this.showCookiesNotification = false;
    this.localStorageService.setPolyce(COOKIES_KEY, false);
  }
}
