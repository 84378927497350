<ng-container *ngIf="hasError()">
  <p
    class="text-danger clearfix"
    style="
      font-size: 13px;
      margin: 8px 0px 20px;
      line-height: 1;
      white-space: normal;
    "
  >
    {{ getErrorMessage() }}
  </p>
</ng-container>
