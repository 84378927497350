<div class="card card-service" (click)="navigationProductDetails(property.id)">
    <div *ngIf="property?.promotion_price!=0" class="price" class="desconto text-center">
        {{property?.promotion_percent | number: '1.0-0'}}% OFF</div>
    <div style="display: flex;">
        <img *ngIf="image?.name != null" class="card-img" [src]="imgUrl" alt="Card image cap">
        <img *ngIf="image?.name == null" class="card-img" src="https://dummyimage.com/400x400/d1d1d1/ffffff.png"
            alt="Card image cap">
    </div>

    <div class="card-detalhe">
        <button class="btn btn-block confirm" [disabled]="activeButton">{{buttonAvailable | uppercase}}</button>
    </div>

    <div class="card-body card-body-andamento px-0 pb-0" style="height: 300px;">
        <div class="textos-card">
            <div class="mx-1 mt-2 mb-2 tamanho">
                <span class="andamento-text mb-3">{{property?.commercial_name}}</span>
            </div>
            <div class="price-dtl">
                <div *ngIf="property?.promotion_price!=0" class="row" style="height: 30px;">

                    <div class="col-6">
                        <p class="price" class="price-salle">
                            {{property?.price|currency:"BRL":true}}
                        </p>
                    </div>
                </div>


                <div class="row">
                    <div class="col-12">
                        <p *ngIf="property?.promotion_price!=0 && property?.type_sale!=2" class="price">
                            <small>Por:</small> {{property?.promotion_price|currency:"BRL":true}}
                        </p>

                        <p *ngIf="property?.promotion_price == 0 && property?.type_sale!=2" class="price">
                            {{property?.price|currency:"BRL":true}}
                        </p>

                    </div>
                </div>

                <div class="_80" *ngIf="property?.type_sale != 2" style="height: 20px">
                    <small *ngIf="property?.payment_condition" style="font-size:0.8rem;"> em
                        {{property?.payment_condition}}</small>
                </div>


            </div>
        </div>
    </div>

</div>
<ng-container *ngIf="property.is_enabled == 1">
</ng-container>