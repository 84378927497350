<ngx-skeleton-loader count="1" [theme]="{
    'display': 'block',
    'width': '70%',
    'background-color': '#ddd'
  }">
</ngx-skeleton-loader>

<ngx-skeleton-loader count="1" [theme]="{
    'display': 'block',
    'width': '98%',
    'background-color': '#ddd'
  }">
</ngx-skeleton-loader>

<ngx-skeleton-loader count="1" [theme]="{
    'display': 'block',
    'width': '80%',
    'background-color': '#ddd'
  }">
</ngx-skeleton-loader>

<ngx-skeleton-loader count="1" [theme]="{
    'display': 'block',
    'width': '70%',
    'background-color': '#ddd'
  }">
</ngx-skeleton-loader>
