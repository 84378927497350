import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericResultCommand } from '../commands/core/generic-result.command';
import { ServiceBase } from './core/service-base.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends ServiceBase<any> {

  constructor(protected injector: Injector) {
    super('/settings', injector)
  }

  getSettings(): Observable<GenericResultCommand> {
    return this.http.get<GenericResultCommand>(`${this.apiUrl}`)
  }
  
}
