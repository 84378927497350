export const environment = {
  production: false,
  //produção
   app: '635a983ac2cfa',
   apiUrl: `https://app.mercaecommerce.com/api/v1`,
   apiStorageUrl: `https://app.mercaecommerce.com/storage/`

  //teste
  /* app: `62d050f35dd5c`,
  apiUrl: `https://local360.com.br/merca/api/v1`,
  apiStorageUrl: `https://local360.com.br/merca/storage/` */
};
