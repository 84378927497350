import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { Products } from 'src/app/models/products.model';
import { Image } from 'src/app/models/products.model';
import { document } from 'ngx-bootstrap/utils';

@Component({
  selector: 'app-card-sem-salle',
  templateUrl: './card-sem-salle.component.html',
  styleUrls: ['./card-sem-salle.component.scss'],
})
export class CardSemSalleComponent implements OnInit {
  @Input('property') property?: Products;
  image?: Image;
  buttonAvailable: string;
  activeButton: boolean;
  quantidade: number = 0;
  imgUrl: String = environment.apiStorageUrl;

  constructor(private route: Router) {}

  ngOnInit(): void {
    this.image = this.property?.images[0];
    this.quantidade = parseInt(this.property.quantity);
    this.checkAvailability();
  }

  navigationProductDetails(product): void {
    this.route.navigate([
      '/product-details',
      { produto: JSON.stringify(product) },
    ]);
    document.location.reload();
  }

  //validaçao da quantidade de produtos disponiveis
  checkAvailability(): void {
    if (this.quantidade <= 0) {
      this.buttonAvailable = 'Indisponível';
      this.activeButton = true;
    } else {
      this.buttonAvailable = 'Comprar';
      this.activeButton = false;
    }
  }
}
