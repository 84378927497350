import { Injectable, Injector } from '@angular/core';
import { GenericResultCommand } from '../commands/core/generic-result.command';
import { HeaderUtil } from '../utils/header.util';
import { ServiceBase } from './core/service-base.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethods extends ServiceBase<any> {
  constructor(protected injector: Injector) {
    super('', injector);
  }

  getPaymentMethods(): Promise<GenericResultCommand> {
    return this.http
      .get<GenericResultCommand>(`${this.apiUrl}/payment-methods`, {
        headers: HeaderUtil.get(),
      })
      .toPromise();
  }
}
