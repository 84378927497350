import { Injectable, Injector } from '@angular/core';
import { ServiceBase } from './core/service-base.service';

@Injectable({
  providedIn: 'root'
})
export class CityStateService extends ServiceBase<any> {

  constructor(protected injector: Injector) {
    super('/', injector);
  }

  getStates(): Promise<any> {
    return this.http.get(`${this.apiUrl}states`).toPromise();
  }
  getCityId(state_id) {
    return this.http.get(`${this.apiUrl}cities?state=${state_id}`);
  }
  getCityInfos(city_id) {
    return this.http.get(`${this.apiUrl}cities/${city_id}`);
  }
}
