import { Injectable, Injector } from '@angular/core';
import { GenericResultCommand } from '../commands/core/generic-result.command';
import { HeaderUtil } from '../utils/header.util';
import { ServiceBase } from './core/service-base.service';

@Injectable({
  providedIn: 'root',
})
export class FaqService extends ServiceBase<any> {
  constructor(protected injector: Injector) {
    super('', injector);
  }

  getFaq(): Promise<GenericResultCommand> {
    return this.http
      .get<GenericResultCommand>(`${this.apiUrl}/faqs`, {
        headers: HeaderUtil.get(),
      })
      .toPromise();
  }

  showFaq(id): Promise<GenericResultCommand> {
    return this.http
      .get<GenericResultCommand>(`${this.apiUrl}/faqs/${id}`, {
        headers: HeaderUtil.get(),
      })
      .toPromise();
  }

  getParam(): Promise<GenericResultCommand> {
    return this.http
      .get<GenericResultCommand>(`${this.apiUrl}/parameters`, {
        headers: HeaderUtil.get(),
      })
      .toPromise();
  }
}
