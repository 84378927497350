import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Products } from 'src/app/models/products.model';
import { Image } from 'src/app/models/products.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PagseguroService } from 'src/app/services/pagseguro.service';
import { PaymentMethods } from 'src/app/services/payment-methods.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() property: any;
  image?: Image;
  buttonAvailable: string;
  activeButton: boolean;
  percentual: Number;
  quantidade: number = 0;
  imgUrl: String = environment.apiStorageUrl;
  parcela_disponivel: any;
  formas_de_pagamento: any;

  constructor(
    private route: Router,
    private pagseguroservices: PagseguroService,
    private paymentmethodsservice: PaymentMethods,
    private local_storage_service: LocalStorageService
  ) {
    this.getInstallments(this.property)
  }

  ngOnInit(): void {
    this.removeDecimal();
    this.image = this.property?.images[0];
    this.quantidade = parseInt(this.property?.quantity);
    this.checkAvailability();
    this.getInstallments(this.property);
    this.getPaymentMethods()
  }

  removeDecimal() {
    this.percentual = Math.trunc(Number(this.property?.discount));
  }

  navigationProductDetails(product) {
    this.route.navigate([
      '/product-details',
      { produto: JSON.stringify(product) },
    ]);
  }

  getPaymentMethods() {
    this.formas_de_pagamento = this.local_storage_service.getItem("payment_methods");
  }

  getInstallments(product: Products) {
    let valor_produto: number = 0
    if (product?.promotion_price > 0) {
      valor_produto = product?.promotion_price
    } else {
      valor_produto = product?.price
    }

    let parcelas = valor_produto / 10
    this.parcela_disponivel = `10x de R$ ${parcelas.toFixed(0)},00 sem juros `
  }

  checkAvailability() {
    if (this.quantidade <= 0) {
      this.buttonAvailable = 'Indisponível';
      this.activeButton = true;
    } else {
      this.buttonAvailable = 'Comprar';
      this.activeButton = false;
    }
  }
}
