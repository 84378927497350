<!-- <swiper [config]="config">
    <div *ngFor="let product of products; let i = index;" class="swiper-slide">
        <div *ngIf="i < 10" class="card-group">
            <app-card [property]="product"></app-card>
        </div>
    </div>
</swiper> -->

<div class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper">
        <div *ngFor="let product of products; let i = index;" class="swiper-slide">
            <div *ngIf="i < 10" class="card-group">
                <app-card [property]="product"></app-card>
            </div>
        </div>
    </div>
</div>
<div class="swiper-button-prev" style="left:-30px;"></div>
<div class="swiper-button-next" style="right:-30px;"></div>