<div class="accordion" [id]="'accordion'+i" *ngFor="let todasCategorias of category; let i = index">
  <div class="accordion-item">
    <h2 class="accordion-header categoriasSlider" [id]="'headingOne'+i">
      <a class="accordion-button title-category" href="#shippingOptions" role="button" data-bs-toggle="collapse" (click)="acordeonClicked(i)"
        [attr.data-bs-target]="'#collapseOne'+i" aria-controls="shippingOptions" aria-expanded="false" [attr.aria-controls]="'collapseOne'+i">
        {{todasCategorias.name}}
      </a>
    </h2>
    <div [id]="'collapseOne'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingOne'+i"
      [attr.data-bs-parent]="'#accordion'+i">
      <div class="accordion-body">
        <a *ngIf="todasCategorias.children.length == 0" class="nav-link" routerLinkActive="active"
          (click)="naveCategory(todasCategorias.id!)" style="font-family: Inter-Bold; cursor: pointer;">
          {{ todasCategorias.name }}
        </a>

        <div *ngFor="let sub of todasCategorias.children" class="">
          <div>
            <a *ngIf="sub.children.length != 0" class="title-category" routerLinkActive="active" (click)="naveCategory(sub.id!)"
              style="cursor: pointer; text-transform: capitalize; font-family: Inter-SemiBold; " class=""
              (click)="(false)">{{ sub.name }}
            </a>

            <div *ngFor="let sub2 of sub.children">
              <a class="title-category" routerLinkActive="active" (click)="naveCategory(sub2.id!)" class=""
                style="font-size: 14px; cursor: pointer;">
                {{ sub2.name}}
              </a>
            </div>
          </div>

          <div>
            <a *ngIf="sub.children.length == 0" class="title-category" routerLinkActive="active" (click)="naveCategory(sub.id!)" class=""
              style="cursor: pointer; text-transform: capitalize; font-family: Inter-SemiBold; " class="">
              {{sub.name}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
